<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px">
					<div class="flex space-x-2 inner">
						<div class="mt-2">
							기준년월
						</div>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							v-model="searchType.customTypes.frScheYmd"
							type="date"
							dateSerializationFormat="yyyyMMdd"
							date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
							display-format="yyyy.MM"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:max="searchType.customTypes.toScheYmd"
							maxLength="7"
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayStartChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-1">
							~
						</div>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							v-model="searchType.customTypes.toScheYmd"
							type="date"
							dateSerializationFormat="yyyyMMdd"
							date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
							display-format="yyyy.MM"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:min="searchType.customTypes.frScheYmd"
							maxLength="7"
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayEndChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-2">
							성명
						</div>
						<DxButton
							styling-mode="outlined"
							:text="searchType.customTypes.targetName"
							:width="120"
							:height="30"
							class="btn_XS white light_filled"
							@click="onOpenTarget"
						>
						</DxButton>
						<DxTextBox
							styling-mode="filled"
							v-model="searchType.customTypes.targetId"
							:width="120"
							:height="30"
							:read-only="true"
							class="mar_ri10"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="대상자는 필수입니다." />
							</DxValidator>
						</DxTextBox>

						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectData" />
					</div>
				</div>
			</div>
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<div class="sub_title_txt my-4 flex">
						<h2>평가요약</h2>
						<div class="flex justify-end flex-grow">
							<div class="total-count-item">
								검색결과 <span class="tet-calr1">{{ evalSummaryGridData.totalCount }}</span> 개
							</div>
						</div>
					</div>
					<DxDataGrid
						key-expr="key"
						class="grid-box eval-grid"
						ref="evalScheduleGrid"
						:data-source="evalSummaryGridData.dataSource"
						:allow-column-resizing="true"
						:column-resizing-mode="'nextColumn'"
						:show-borders="false"
						:show-column-headers="true"
						:show-column-lines="true"
						:show-row-lines="true"
						:row-alternation-enabled="false"
						:hover-state-enabled="true"
						:word-wrap-enabled="true"
						:no-data-text="this.$_msgContents('CMN_NO_DATA')"
						width="100%"
						:height="230"
					>
						<DxPaging :enabled="false" />
						<DxLoadPanel :enabled="true" />
						<DxScrolling mode="standard" />
						<!-- <DxSelection
							mode="single"
							:allow-select-all="false"
							show-check-boxes-mode="always"
							select-all-mode="allPages"
						/> -->
						<DxColumn
							caption="평가구분"
							data-field="evalDivisionCd"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						>
							<DxLookup :data-source="dataGridLoopup.evalDivisionCd" value-expr="codeId" display-expr="codeNm" />
						</DxColumn>
						<!-- <template #evalDivisionCdTemplate="{ data }">
							<span>{{ evalSummaryGridData.dataSource != null && data.rowIndex != 0
								&& evalSummaryGridData.dataSource[data.rowIndex - 1].evalDivisionCd == data.data.evalDivisionCd ? ''
									: dataGridLoopup.evalDivisionCd.filter(z => data.data.evalDivisionCd == z.codeId)[0].codeNm }}</span>
						</template> -->
						<DxColumn caption="평가계획명" data-field="scheNm" :allowEditing="false" :allow-sorting="true" />
						<DxColumn caption="평가표" data-field="sheetNm" :allowEditing="false" :allow-sorting="true" />
						<DxColumn
							caption="전체 평균점수"
							data-field="allAvgScore"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="총점"
							data-field="totalSore"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="평가표 상위구성"
							data-field="questionParentGroup"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="평가표 하위구성"
							data-field="questionGroup"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="배점"
							data-field="questionScore"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="평가점수"
							data-field="score"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
					</DxDataGrid>
				</div>
				<div>
					<div class="sub_title_txt my-4 flex">
						<h2>12개월간 평가점수 추이</h2>
						<div class="flex justify-end flex-grow">
							<div class="total-count-item">
								검색결과 <span class="tet-calr1">{{ trendTwelveMonthsGridData.totalCount }}</span> 개
							</div>
						</div>
					</div>
					<div class="mt-4">
						<DxDataGrid
							key-expr="key"
							class="grid-box eval-grid"
							ref="trendTwelveMonthsGrid"
							:data-source="trendTwelveMonthsGridData.dataSource"
							:allow-column-resizing="true"
							:column-resizing-mode="'nextColumn'"
							:show-borders="false"
							:show-column-headers="true"
							:show-column-lines="true"
							:show-row-lines="true"
							:row-alternation-enabled="false"
							:hover-state-enabled="true"
							:word-wrap-enabled="true"
							:no-data-text="this.$_msgContents('CMN_NO_DATA')"
							width="100%"
							height="150"
						>
							<DxLoadPanel :enabled="true" />
							<DxScrolling mode="standard" />
							<!-- <DxSelection
								mode="single"
								:allow-select-all="false"
								show-check-boxes-mode="always"
								select-all-mode="allPages"
							/> -->
							<DxColumn
								caption="평가구분"
								data-field="evalDivisionCd"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							>
								<DxLookup :data-source="dataGridLoopup.evalDivisionCd" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								v-for="index in 12"
								:key="index"
								:caption="getTrendTwelveMonthsGridCaption(index)"
								:data-field="`totalScore_${index}`"
								:allow-sorting="false"
								alignment="center"
								:calculate-display-value="
									rowData => (rowData[`totalScore_${index}`] ? rowData[`totalScore_${index}`] : '-')
								"
							/>
						</DxDataGrid>
					</div>
				</div>
				<div>
					<div class="sub_title_txt my-4 flex">
						<h2>12개월간 평가점수 추이(chart)</h2>
					</div>
					<div class="mt-4">
						<DxChart id="trendTwelveMonthsChart" :data-source="trendTwelveMonthsChartData.dataSource">
							<DxCommonSeriesSettings type="line" argument-field="scheYm" />
							<DxSeries
								v-for="(s, index) in trendTwelveMonthsChartData.series"
								:key="index + 1"
								:value-field="s.evalDivisionCd.toString()"
								:name="s.evalDivisionNm"
								:ignore-empty-points="true"
							>
								<DxLabel :visible="false" />
								<DxPoint :size="10" />
							</DxSeries>
							<DxMargin :bottom="0" />
							<DxArgumentAxis :value-margins-enabled="false" discrete-axis-division-mode="crossLabels">
								<DxGrid :visible="true" />
							</DxArgumentAxis>
							<DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
							<DxExport :enabled="false" />
							<DxTooltip :enabled="true" />
						</DxChart>
					</div>
				</div>
			</div>
		</div>

		<modal-add-agent
			:isOpen="agent.popupVisible"
			:showModalTitle="true"
			:selectedIdList="[]"
			@closeModal="onCloseTarget(false)"
			@saveModal="onSelectTarget"
			:minimumSelectionLength="1"
			:maximumSelectionLength="1"
			:saveBtnTxt="'선택'"
		/>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxColumn, DxDataGrid, DxLoadPanel, DxLookup, DxPaging, DxScrolling } from 'devextreme-vue/data-grid';
import {
	DxChart,
	DxSeries,
	DxArgumentAxis,
	DxCommonSeriesSettings,
	DxExport,
	DxGrid,
	DxLabel,
	DxMargin,
	DxLegend,
	DxPoint,
	DxTooltip,
} from 'devextreme-vue/chart';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import moment from "moment";

let vm = null;

export default {
	components: {
		DxButton,
		DxDateBox,

		DxColumn,
		DxDataGrid,
		DxLoadPanel,
		DxLookup,
		DxScrolling,

		DxChart,
		DxSeries,
		DxArgumentAxis,
		DxCommonSeriesSettings,
		DxExport,
		DxGrid,
		DxLabel,
		DxMargin,
		DxLegend,
		DxPaging,
		DxPoint,
		DxTextBox,
		DxTooltip,

		DxValidator,
		DxRequiredRule,

		ModalAddAgent,
	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			dataGridLoopup: {
				evalDivisionCd: [],
			},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			agent: {
				popupVisible: false,
			},
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					frScheYmd: getPastFromToday(11, 'months'),
					toScheYmd: getPastFromToday(0, 'days'),
					isFeedback: false,
					targetName: '', //this.$store.getters.getLoginNm,
					targetId: '', //this.$store.getters.getLoginId,
				},
				paramsData: {},
			},
			evalSummaryGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				totalCount: 0,
			},
			trendTwelveMonthsGridData: {
				dataSource: null,
				dataFieldIdx: {},
				trendTwelveMonthsGridColumns: [],
				totalCount: 0,
			},
			trendTwelveMonthsChartData: {
				dataSource: null,
				series: [],
				trendMonths: [],
			},
		};
	},
	computed: {},
	methods: {
		onOpenTarget() {
			this.agent.popupVisible = true;
		},
		async onCloseTarget() {
			this.agent.popupVisible = false;
		},
		onSelectTarget(value) {
			this.searchType.customTypes.targetName = value[0].agtNm;
			this.searchType.customTypes.targetId = value[0].agtid;
			this.onCloseTarget().then(() => this.selectData());
		},
		/** @description : 초기 12개월간 평가점수 추이 그리드 캡션 설정 */
		getTrendTwelveMonthsGridCaption(index) {
			return moment(getPastFromToday(0, 'days'))
				.subtract(12 - index, 'months')
				.format('YYYY-MM');
		},
		selectData() {
			if (!validationEngine.validateGroup('validationSearch').isValid) {
				return;
			}

			this.selectEvalSummaryGridDataList();
			this.selectTrendTwelveMonthsDataList();
		},
		/** @description : 평가요약 조회 */
		async selectEvalSummaryGridDataList() {
			vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;
			vm.searchType.paramsData['targetId'] = vm.searchType.customTypes.targetId;

			const payload = {
				actionname: 'EWM_EVALUATION_PERSONAL_REPORT_EVAL_SUMMARY',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.evalSummaryGridData.dataSource = res.data.data;
				this.evalSummaryGridData.totalCount = res.data.data.length;
			}
		},
		/** @description : 12개월간 평가점수 추이 조회 */
		async selectTrendTwelveMonthsDataList() {
			vm.searchType.paramsData['frScheYmd'] = this.$_commonlib
				.moment(vm.searchType.customTypes.toScheYmd)
				.subtract(11, 'months')
				.format('YYYYMMDD');
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;
			vm.searchType.paramsData['targetId'] = vm.searchType.customTypes.targetId;

			this.setTrendTwelveMonthsGridColumns(vm.searchType.customTypes.toScheYmd, 11);

			const payload = {
				actionname: 'EWM_EVALUATION_PERSONAL_REPORT_TREND_12M',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				const evalDivisionCds = [...new Set(res.data.data.map(z => z.evalDivisionCd))];

				const trendTwelveMonthsGridDataSource = evalDivisionCds.map((z, index) => {
					const rowData = {
						key: index + 1,
						evalDivisionCd: z,
						evalDivisionNm: this.dataGridLoopup.evalDivisionCd.find(el => el.codeId == z).codeNm,
					};

					res.data.data
						.filter(el => el.evalDivisionCd == z)
						.forEach(e => {
							const dataFieldIdx = this.trendTwelveMonthsGridData.dataFieldIdx[e.scheYm];
							rowData[`totalScore_${dataFieldIdx}`] = e.totalScore;
							rowData['targetAgtId'] = e.targetAgtId;
							rowData['scheYm'] = e.scheYm;
						});

					return rowData;
				});

				this.trendTwelveMonthsGridData.dataSource = trendTwelveMonthsGridDataSource;
				this.trendTwelveMonthsGridData.totalCount = trendTwelveMonthsGridDataSource.length;

				this.trendTwelveMonthsChartData.series = trendTwelveMonthsGridDataSource;

				console.log('this.trendTwelveMonthsChartData.series', this.trendTwelveMonthsChartData.series);

				const chartDs = [];

				this.trendTwelveMonthsChartData.trendMonths.forEach(y => {
					const chartData = {
						scheYm: y,
					};

					evalDivisionCds.forEach(z => {
						const dataIndex = res.data.data.findIndex(re => re.evalDivisionCd === z && re.scheYm === y);

						chartData[z] = dataIndex > -1 ? res.data.data[dataIndex].totalScore : null;
					});

					chartDs.push(chartData);
				});

				this.trendTwelveMonthsChartData.dataSource = chartDs;

				console.log('this.trendTwelveMonthsChartData.dataSource', this.trendTwelveMonthsChartData.dataSource);
			}
		},
		/** @description : 12개월 평가 추이 그리드 캡션 세팅 */
		setTrendTwelveMonthsGridColumns(toScheYmd, pastTrendMonths) {
			this.trendTwelveMonthsChartData.trendMonths = [];

			let count = 0;
			while (count <= pastTrendMonths) {
				const scheYmd = this.$_commonlib
					.moment(toScheYmd)
					.subtract(pastTrendMonths - count, 'months')
					.format('yyyy-MM');
				this.trendTwelveMonthsGridData.dataFieldIdx[scheYmd] = count + 1;
				this.trendTwelveMonthsChartData.trendMonths.push(scheYmd);

				this.$refs.trendTwelveMonthsGrid.instance.columnOption(`totalScore_${count + 1}`, 'caption', scheYmd);

				count++;
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.frScheYmd = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.toScheYmd = changed;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
		},
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.dataGridLoopup.evalDivisionCd = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
			});
		},
		mountedData() {
			//this.selectData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
<style lang="scss" scoped>
.page_search_box .inner div {
	display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
.page_search_box #searchTooltip {
	margin-right: 5px;
	font-size: 22px;
	color: #808080;
}
#trendTwelveMonthsChart {
	height: 300px;
}
</style>
